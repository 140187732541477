import React from 'react'
import App from 'next/app'

import('@sentry/browser').then((mod) => {
	if (process.env.NODE_ENV === 'production') {
		mod.init({
			dsn: process.env.SENTRY_DSN,
			release: process.env.SENTRY_RELEASE,
			sampleRate: 1,
			environment: 'logan',
			autoSessionTracking: false,
		})
	}
	return mod
})

class MyApp extends App {
	componentDidCatch(error: any, errorInfo: any) {
		if (process.env.NODE_ENV === 'production') {
			import('@sentry/browser').then((mod) => {
				mod.withScope((scope: any) => {
					Object.keys(errorInfo).forEach((key) => {
						scope.setExtra(key, errorInfo[key])
					})

					mod.captureException(error)
				})
			})
		}

		super.componentDidCatch(error, errorInfo)
	}

	render() {
		const { Component, pageProps } = this.props

		return <Component {...pageProps} />
	}
}

export default MyApp
